<template>
  <div>
    <side-form
      :is-add-new-page-sidebar-active.sync="isShowFormAdd"
      :is-loading="isLoading"
      :type-form="typeForm"
      :data="warehouseData"
      @onSubmit="onSubmit"
    />
    <b-card>
      <own-table
        ref="warehouseTable"
        name-table="warehouse-commerce"
        :table-columns="columns"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        :export-excel="false"
        @searchFieldOnChange="searchOnChange"
        @evt-add="showAddForm"
      >
        <template #cell(no)="data">
          <span>{{ data.data.index + 1 }}</span>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.data.item.created_at) }}</span>
        </template>
        <template #cell(is_default)="{ data }">
          <b-badge pill :variant="data.item.is_default ? 'success' : 'danger'">
            <feather-icon
              :icon="data.item.is_default ? 'CheckIcon' : 'XIcon'"
            />
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              menu-class="position-absolute"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="edit(data.data.item)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="onDelete(data.data.item.id)">
                <!-- <feather-icon icon="action.icon" /> -->
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import { BCard, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import OwnTable from '@/components/Table/Table.vue'
import store from '@/store'
import { formatDate, formatCurrency } from '@/@core/utils/filter'
import newsNotificationModule from './newsNotificationModule'

export default {
  components: {
    BCard,
    OwnTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    SideForm,
  },
  setup() {
    const WAREHOUSE_STORE_MODULE = 'warehouses'
    if (!store.hasModule(WAREHOUSE_STORE_MODULE)) {
      store.registerModule(WAREHOUSE_STORE_MODULE, newsNotificationModule)
    }
    onUnmounted(() => {
      store.unregisterModule(WAREHOUSE_STORE_MODULE)
    })
    const warehouseTable = ref(null)
    const columns = ref([
      {
        label: 'No',
        key: 'no',
      },
      {
        label: 'Kode Warehouse',
        key: 'warehouse_code',
        sortable: true,
      },
      {
        label: 'Nama Warehouse',
        key: 'warehouse_name',
        sortable: true,
      },
      {
        label: 'Kecamatan',
        key: 'district',
        sortable: true,
      },
      {
        label: 'Kota',
        key: 'city',
        sortable: true,
      },
      {
        label: 'Provinsi',
        key: 'province',
        sortable: true,
      },
      {
        label: 'default',
        key: 'is_default',
        sortable: true,
      },
      {
        label: 'Created At',
        key: 'created_at',
        sortable: true,
      },
      {
        label: 'Actions',
        key: 'actions',
      },
    ])
    const isLoading = ref(false)
    const isShowFormAdd = ref(false)
    const queryFilter = ref('')
    const search = ref('')
    const typeForm = ref('add')
    const warehouseData = ref(null)
    const onSubmit = formData => {
      isLoading.value = true
      warehouseTable.value.submit(formData, isSuccess => {
        if (isSuccess) {
          isShowFormAdd.value = false
        }
        isLoading.value = false
      })
    }

    const statusVariant = status => {
      if (status === 1) {
        return 'success'
      }
      return 'danger'
    }

    const showAddForm = () => {
      isShowFormAdd.value = true
      warehouseData.value = null
    }

    const searchOnChange = value => {
      queryFilter.value = `&q=${value}`
    }

    const edit = data => {
      typeForm.value = 'edit'
      warehouseTable.value.onEdit()
      warehouseData.value = data
      isShowFormAdd.value = true
    }

    const onDelete = id => {
      warehouseTable.value.onDelete(id)
    }
    // const formatDateTime = date => formatDate(date)
    return {
      columns,
      queryFilter,
      warehouseTable,
      statusVariant,
      formatDate,
      formatCurrency,

      showAddForm,
      isShowFormAdd,

      searchOnChange,

      onSubmit,

      isLoading,
      edit,
      warehouseData,
      typeForm,
      onDelete,
    }
  },
}
</script>
